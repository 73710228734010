import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
export const useContactUsPage = () => {
    const { contactUsContentfulPage } = useStaticQuery(
        graphql`
            query contactUs {
                contactUsContentfulPage: allContentfulPage(filter: {slug: {eq: "contact-us"}}) {
                    contactUs: edges {
                        node {
                            title
                            metaDescription {
                                metaDescription
                            }
                            metaTags {
                                metaTags
                            }
                            image {
                                image {
                                    fluid(maxWidth: 600, maxHeight: 400) {
                                        aspectRatio
                                        base64
                                        sizes
                                        src
                                        srcSet
                                    }
                                }
                            }
                            leadingParagraph {
                                json
                            }
                            contentBlocks {
                                ...ContentfulRichTextContentBlockFragment
                            }
                        }
                    }
                }
            }
        `,
    )

    return {
        contactUsContentfulPage: contactUsContentfulPage,
    }
}

useContactUsPage.proptypes = {
    edges: PropTypes.any,
}
