import ContentBlocks from '../components/content-blocks'
import HeaderHero from '../components/header-hero'
import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import { useContactUsPage } from '../hooks/contact-us'
import '../components/contact.scss'
import '../components/radio.scss'

const Contact = () => {
    const { contactUs } = useContactUsPage().contactUsContentfulPage
    const contactUsData = contactUs && contactUs[0].node

    return (
        <Layout>
            <SEO
                title="Contact"
            />
            <SEO
                title={contactUsData.title}
                description={contactUsData.metaDescription && contactUsData.metaDescription.metaDescription ? contactUsData.metaDescription.metaDescription : null}
                imageUrl={contactUsData.image && contactUsData.image.image.fluid.src.replace(/^\/\//, 'https://')}
                tags={contactUsData.metaTags && contactUsData.metaTags.metaTags}
            />
            <HeaderHero
                title={contactUsData.title}
                image={contactUsData.image}
                leadingParagraph={contactUsData.leadingParagraph}
                path={'/contact/'}
            />
            <div className="contact">
                <div className="contact-content-blocks || contact-section">
                    <ContentBlocks data={contactUsData.contentBlocks}/>
                </div>
                <div className="contact-section">
                    <form className="contact-form" action="https://getform.io/f/e6775123-fa96-4e37-acd9-dec14fd06891" method="POST">
                        <p><b>Contact Us</b></p>
                        <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                        <div className="field">
                            <div className="control">
                                <label className="label">First Name <span className="asterisk">*</span></label>
                                <input className="input required" name="FNAME" type="text" placeholder="First name"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Last Name <span className="asterisk">*</span></label>
                                <input className="input required" name="LNAME" type="text" placeholder="Last name"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Email Address <span className="asterisk">*</span></label>
                                <input className="input required" name="EMAIL" type="email" placeholder="Email"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Phone Number</label>
                                <input className="input" name="PHONE" type="tel" placeholder="Phone number"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Company or School Name <span className="asterisk">*</span></label>
                                <input className="input required" name="COMPANY" type="text" placeholder="Company or school"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Region <span className="asterisk">*</span></label>
                                <div className="select">
                                    <select name="REGION">
                                        <option>Choose</option>
                                        <option>Auckland</option>
                                        <option>Australia</option>
                                        <option>Bay of Plenty</option>
                                        <option>Canterbury</option>
                                        <option>Chatham Islands</option>
                                        <option>Christchurch</option>
                                        <option>Gisborne</option>
                                        <option>Hawkes Bay</option>
                                        <option>Manawatu-Wanganui</option>
                                        <option>Marlborough</option>
                                        <option>Nelson</option>
                                        <option>Northland</option>
                                        <option>Otago</option>
                                        <option>Southland</option>
                                        <option>Taranaki</option>
                                        <option>Tasman</option>
                                        <option>Waikato</option>
                                        <option>Wellington</option>
                                        <option>West Coast</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Are you a School Employee? <span className="asterisk">*</span></label>
                            <div className="control form-field radio">
                                <input type="radio" name="EMPLOYEE" id="yes" value="yes"/><label className="form-field-label" htmlFor="yes">Yes</label>
                                <input type="radio" name="EMPLOYEE" id="no" value="no"/><label className="form-field-label" htmlFor="no">No</label>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">How can we help you? <span className="asterisk">*</span></label>
                                <textarea className="textarea required" name="COMMENTS" />
                            </div>
                        </div>
                        <button type="submit" value="Submit" name="subscribe" className="button primary">Submit</button>
                    </form>
                </div>
            </div>
            <iframe className="contact-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3189.4951134349076!2d174.78084331529348!3d-36.926334279922635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc6b2f869f30841a7!2sSpringboard%20Trust!5e0!3m2!1sen!2snz!4v1580783988113!5m2!1sen!2snz"
                frameBorder="0"
                allowFullScreen=""
            />
        </Layout>
    )
}

export default Contact
